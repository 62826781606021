import Close from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import React from "react";
import styled from "styled-components";
import { MdOutlinePayments, MdStoreMallDirectory } from "react-icons/md";
import { BsFillRocketFill, BsWindowPlus } from "react-icons/bs";
import {
  FaBoxOpen,
  FaFileImport,
  FaPeopleCarry,
  FaUsers,
} from "react-icons/fa";
import { FaShapes } from "react-icons/fa6";
import { BsTagsFill } from "react-icons/bs";
import { LiaCartPlusSolid } from "react-icons/lia";
import { TbShoppingCartMinus } from "react-icons/tb";
import { BiChevronRight } from "react-icons/bi";
import { BiSolidCopy } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setIsImportProductsModalVisible,
  setIsNewProductModalVisible,
  setIsNewTagModalVisible,
} from "../../../../context/reducers/layout";
import {
  setIsNewIncomeDrawerOpen,
  setIsNewPurchaseDrawerOpen,
  setIsNewSaleDrawerOpen,
} from "../../../../context/reducers/transactions";
import { setIsNewExpenseDrawerOpen } from "../../../../context/reducers/transactions";
import { setIsNewCategoryModalVisible } from "../../../../context/reducers/categories";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ONLINE_STORE = "/online-store";
const INVENTORY = "/inventory";
const TRANSACTIONS = "/transactions";
const CLIENTS = "/clients";
const PROVIDERS = "/providers";

export default function QuickOptionsModal({ isVisible, setIsVisible }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    setIsVisible(false);
  };

  const options = [
    {
      title: "Ver datos de mi tienda virtual",
      icon: <MdStoreMallDirectory />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: ONLINE_STORE,
          search: "?tab=STORE_DATA",
        });
      },
    },
    {
      title: "Personalizar mi tienda virtual",
      icon: <BiSolidCopy />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: ONLINE_STORE,
          search: "?tab=TEMPLATES",
        });
      },
    },
    {
      title: "Impulsar mi tienda virtual",
      icon: <BsFillRocketFill />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: ONLINE_STORE,
          search: "?tab=ADVERTISING",
        });
      },
    },
    {
      title: "Nuevo producto",
      icon: <FaBoxOpen />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: INVENTORY,
          search: "?tab=GENERAL",
        });
        dispatch(
          setIsNewProductModalVisible({
            isNewProductModalVisible: true,
          })
        );
      },
    },
    {
      title: "Importar productos desde Excel",
      icon: <FaFileImport />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: INVENTORY,
          search: "?tab=GENERAL",
        });
        dispatch(
          setIsImportProductsModalVisible({
            isImportProductsModalVisible: true,
          })
        );
      },
    },
    {
      title: "Nueva categoría",
      icon: <FaShapes />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: INVENTORY,
          search: "?tab=CATEGORIES",
        });
        dispatch(
          setIsNewCategoryModalVisible({
            isNewCategoryModalVisible: true,
          })
        );
      },
    },
    {
      title: "Nueva etiqueta",
      icon: <BsTagsFill />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: INVENTORY,
          search: "?tab=TAGS",
        });
        dispatch(
          setIsNewTagModalVisible({
            isNewTagModalVisible: true,
          })
        );
      },
    },
    {
      title: "Nueva venta",
      icon: <LiaCartPlusSolid style={{ fontSize: "20px" }} />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: TRANSACTIONS,
          search: "?type=SALES",
        });
        dispatch(
          setIsNewSaleDrawerOpen({
            isNewSaleDrawerOpen: true,
          })
        );
      },
    },
    {
      title: "Nueva compra",
      icon: <TbShoppingCartMinus />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: TRANSACTIONS,
          search: "?type=PURCHASES",
        });
        dispatch(
          setIsNewPurchaseDrawerOpen({
            isNewPurchaseDrawerOpen: true,
          })
        );
      },
    },
    {
      title: "Nueva ingreso",
      icon: <BsWindowPlus />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: TRANSACTIONS,
          search: "?type=INCOMES",
        });
        dispatch(
          setIsNewIncomeDrawerOpen({
            isNewIncomeDrawerOpen: true,
          })
        );
      },
    },
    {
      title: "Nueva gasto",
      icon: <MdOutlinePayments />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: TRANSACTIONS,
          search: "?type=EXPENSES",
        });
        dispatch(
          setIsNewExpenseDrawerOpen({
            isNewExpenseDrawerOpen: true,
          })
        );
      },
    },
    {
      title: "Nuevo cliente",
      icon: <FaUsers />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: CLIENTS,
        });
      },
    },
    {
      title: "Nuevo proveedor",
      icon: <FaPeopleCarry />,
      onPressMethod: () => {
        setIsVisible(false);
        navigate({
          pathname: PROVIDERS,
        });
      },
    },
  ];

  const handleClick = (opt: any) => {};

  return (
    <StyledDrawer
      anchor="bottom"
      open={isVisible}
      onClose={handleClose}
      SlideProps={{
        style: {
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Container>
        <Row>
          <Title>Opciones rápidas</Title>
          <IconButton onClick={handleClose}>
            <Close style={{ color: "white" }} />
          </IconButton>
        </Row>
        <Content>
          {options.map((opt, index) => (
            <Option key={index} onClick={opt?.onPressMethod}>
              <OptionIcon>{opt?.icon}</OptionIcon>
              <OptionText>{opt.title}</OptionText>
              <BiChevronRight />
            </Option>
          ))}
        </Content>
      </Container>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)`
  &&& {
  }
`;

const Container = styled.div`
  width: 50vw;
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: scroll;
`;

const Content = styled.div`
  padding-bottom: 50px;
`;

const Row = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 15px;
  background-color: #00a680;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

const Title = styled.p`
  flex: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: white;
`;

const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 15px;
  gap: 10px;
  border-top: 1px solid #c1c1c1;
  cursor: pointer;
  transition: all 0.3s ease;
  :hover {
    background-color: rgba(155, 155, 155, 0.3);
  }
`;

const OptionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OptionText = styled.p`
  flex: 1;
  text-align: left;
  font-size: 14px;
`;
