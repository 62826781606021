import { Close } from "@mui/icons-material";
import { Button, Drawer, IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AdvancedTagsInformationModal({
  isVisible,
  setIsVisible,
}: Props) {
  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <StyledDrawer
      anchor="bottom"
      open={isVisible}
      onClose={handleClose}
      SlideProps={{
        style: {
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Container>
        <Row>
          <Title>Etiquetas avanzadas</Title>
          <IconButton onClick={handleClose}>
            <Close style={{ color: "white" }} />
          </IconButton>
        </Row>
        <Content>
          <Step>
            Ganvent PREMIUM: Estas etiquetas han sido seleccionadas después de
            un análisis exhaustivo, por lo tanto, todas estas etiquetas son
            etiquetas VENDEDORAS para que puedas captar la atención de los
            clientes que visiten tu tienda virtual. Esta etiqueta aparecerá
            encima de la imagen de tu producto. Selecciona la etiqueta que
            prefieras para este producto.. Es de las mejores funcionalidades que
            tenemos
          </Step>
        </Content>
        <Footer>
          <StyledButton onClick={handleClose}>
            <StyledButtonText>OK</StyledButtonText>
          </StyledButton>
        </Footer>
      </Container>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)`
  &&& {
  }
`;

const Container = styled.div`
  width: 50vw;
  background-color: rgb(34, 38, 53);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  max-height: 70vh;
  overflow-y: scroll;
  padding-bottom: 15px;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #c1c1c1;
  padding: 15px;
  margin-top: 12px;
`;

const StyledButton = styled(Button)`
  &&& {
    background-color: #00a680;
    padding: 10px 30px;
  }
`;

const StyledButtonText = styled.p`
  &&& {
    text-transform: none;
    color: white;
  }
`;

const Row = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 15px;
`;

const Title = styled.p`
  flex: 1;
  font-weight: bold;
  font-size: 1.1rem;
  color: #00a680;
`;

const Step = styled.p`
  font-size: 14px;
  color: white;
  padding: 8px 20px;
`;

const StepNumber = styled.span`
  color: white;
  font-weight: 600;
`;

const Subtitle = styled.p`
  color: white;
  font-size: 14px;
  padding: 0px 20px;
`;

const Highlight = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: white;
  padding: 0px 20px;
`;
