import Close from "@mui/icons-material/Close";
import { Button, Drawer, IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RelatedProductsInformationModal({
  isVisible,
  setIsVisible,
}: Props) {
  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <StyledDrawer
      anchor="bottom"
      open={isVisible}
      onClose={handleClose}
      SlideProps={{
        style: {
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Container>
        <Row>
          <Title>Productos relacionados</Title>
          <IconButton onClick={handleClose}>
            <Close style={{ color: "white" }} />
          </IconButton>
        </Row>
        <Content>
          <Row2>
            <Circle />
            <Step>
              En la página de cada uno de tus productos, en tu tienda virtual,
              aparecerá una sección llamada PRODUCTOS RELACIONADOS.
            </Step>
          </Row2>
          <Row2>
            <Circle />
            <Step>
              Con la sección PRODUCTOS RELACIONADOS, podrás aumentar
              considerablemente tus ventas, ya que, cuando un cliente visite la
              página de un determinado producto y decida no comprarlo, entonces
              puede ver la lista de PRODUCTOS RELACIONADOS y optar por comprar
              uno de estos.
            </Step>
          </Row2>
          <Row2>
            <Circle />
            <Step>
              Establece hasta 6 productos relacionados para cada uno de tus
              productos.
            </Step>
          </Row2>
        </Content>
        <Footer>
          <StyledButton onClick={handleClose}>
            <StyledButtonText>OK</StyledButtonText>
          </StyledButton>
        </Footer>
      </Container>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)`
  &&& {
  }
`;

const Container = styled.div`
  width: 50vw;
  background-color: rgb(34, 38, 53);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  max-height: 70vh;
  overflow-y: scroll;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #c1c1c1;
  padding: 15px;
  margin-top: 12px;
`;

const StyledButton = styled(Button)`
  &&& {
    background-color: #00a680;
    padding: 10px 30px;
  }
`;

const StyledButtonText = styled.p`
  &&& {
    text-transform: none;
    color: white;
  }
`;

const Row = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 15px;
  padding-bottom: 0px;
`;

const Title = styled.p`
  flex: 1;
  font-weight: bold;
  font-size: 1.1rem;
  color: #00a680;
`;

const Step = styled.p`
  font-size: 14px;
  color: white;
  flex: 1;
`;

const StepNumber = styled.span`
  color: white;
  font-weight: 600;
`;

const Subtitle = styled.p`
  color: white;
  font-size: 14px;
  padding: 0px 20px;
`;

const Highlight = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: white;
  padding: 0px 20px;
`;

const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  padding: 10px 15px;
`;

const Circle = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 100%;
  background-color: #00a680;
`;
