import { Button, CircularProgress, Drawer, IconButton } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../context/store";
import {
  setGeneralSnackBarMessage,
  setIsImportProductsModalVisible,
  setShowGeneralSnackbar,
} from "../../context/reducers/layout";
import { ArrowBack, Delete as DeleteIcon } from "@mui/icons-material";
import { MdOutlineFileUpload } from "react-icons/md";
import * as XLSX from "xlsx";
import { getAuth } from "firebase/auth";
import CheckUsernameModal from "../global/CheckUsernameModal";
import { collection, doc, setDoc, writeBatch } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import BlockedComponent from "../premium/BlockedComponent";

interface Props {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CATEGORY_NOT_SELECTED = "category not selected";

const PRODUCTS_MAX_QUANTITY = 5;

/**
 * 1. Datos de los productos que vendrán desde el excel
 * name: Es el nombre del producto,
 * barCode: Es el código de barras,
 * stock: El stock es la cantidad disponible que tienes de un producto,
 * price: El precio por compra por unidad s la cantidad de dinero que pagaste para adquirir este producto, es decir, la inversión,
 * salePrice: Es el precio de venta por unidad, al registrar una nueva venta de este producto, podrás cambiar el precio de venta,
 * productNote: Esta nota solo será visible para ti y tus trabajadores,
 *
 * 2. Se debe hacer una validación de que hayan esas columnas, luego de validar el formato
 * debemos validar si los campos encontrados son correctos.
 * También se debe validar que la extensión sea correcta y que solo hayan
 * PRODUCTS_MAX_QUANTITY registros o productos
 *
 * 3. Si la validación falla se debe mostrar un error de validación
 *
 * 4. Esta funcionalidad es solamente PREMIUM
 */

const legendList = [
  {
    name: "name",
    description: "Es el nombre del producto",
    mandatory: true,
  },
  {
    name: "stock",
    description: "El stock es la cantidad disponible que tienes de un producto",
    mandatory: true,
  },
  {
    name: "price",
    description:
      "El precio de compra por unidad es la cantidad de dinero que pagaste para adquirir este producto, es decir, la inversión",
    mandatory: true,
  },
  {
    name: "salePrice",
    description:
      "Es el precio de venta por unidad, al registrar una nueva venta de este producto, podrás cambiar el precio de venta",
  },
  {
    name: "barCode",
    description: "Es el código de barras de tu producto",
  },
  {
    name: "productNote",
    description:
      "Esta nota del producto solo será visuble para ti y tus trabajadores",
  },
];

export default function ImportProductsModal({}) {
  const isVisible = useSelector(
    (state: RootState) => state.layout.isImportProductsModalVisible
  );
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);
  const username = useSelector((state: RootState) => state.layout.username);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [formError, setFormError] = useState<any>({});
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [currencyType, setCurrencyType] = useState(
    selectedBusiness.mainCurrency?.currencyType != null
      ? selectedBusiness.mainCurrency?.currencyType
      : "currencyType not selected"
  );
  const user = getAuth().currentUser;
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const closeModal = () => {
    dispatch(
      setIsImportProductsModalVisible({
        isImportProductsModalVisible: false,
      })
    );
    setFormError({});
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const cleanFields = () => {
    setFormError({});
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    dispatch(
      setIsImportProductsModalVisible({
        isImportProductsModalVisible: false,
      })
    );
  };

  const uploadToFirestoreOWNER = async (products: any[]) => {
    dispatch(
      setShowGeneralSnackbar({
        showSnackbar: true,
      })
    );
    dispatch(
      setGeneralSnackBarMessage({
        snackBarMessage: "Subiendo producto",
      })
    );
    setUploading(true);

    try {
      const batch = writeBatch(db);

      products.forEach((product) => {
        const newProductRef = doc(
          collection(db, "business", selectedBusiness.id, "products")
        );

        batch.set(newProductRef, {
          name: product?.name?.toLowerCase().trim(),
          originalName: product?.name.trim(),
          features: [],
          categoryId: CATEGORY_NOT_SELECTED,
          subcategorysIds: [],
          tagsList: [],
          tagsIDsList: [],
          stock: parseFloat(product?.stock),
          price: parseFloat(product?.price),
          salePrice:
            product?.salePrice != "" &&
            product?.salePrice != null &&
            product?.salePrice !== undefined
              ? parseFloat(product?.salePrice)
              : null,
          currencyType: currencyType,
          images: [],
          expirationDate: null,
          productNote: product?.productNote ?? "",
          createAt: new Date(),
          createAtMoment: moment().format("LLL"),
          avaliable: parseFloat(product?.stock) > 0 ? true : false,
          imageId: null,
          timestamp: new Date(),
          barCode: product?.barCode ? product?.barCode : null,
          videoUrl: null,
        });
      });

      batch
        .commit()
        .then(() => {
          setUploading(false);
          dispatch(
            setShowGeneralSnackbar({
              showSnackbar: true,
            })
          );
          dispatch(
            setGeneralSnackBarMessage({
              snackBarMessage: "Se subieron tus productos correctamente",
            })
          );
          cleanFields();
        })
        .catch((err) => {
          setUploading(false);
          dispatch(
            setShowGeneralSnackbar({
              showSnackbar: true,
            })
          );
          dispatch(
            setGeneralSnackBarMessage({
              snackBarMessage:
                "Ocurrió un error al intentar crear los productos",
            })
          );
        });
    } catch (err) {
      setUploading(false);
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "Ocurrió un error al intentar crear los productos",
        })
      );
    }
  };

  const uploadToFirestoreEMPLOYEE = (products: any[]) => {
    if (username == "" || username == null || username == undefined) {
      setShowUsernameModal(true);
    } else {
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: "Subiendo producto",
        })
      );
      setUploading(true);

      try {
        const batch = writeBatch(db);

        products.forEach((product) => {
          const newProductRef = doc(
            collection(db, "business", selectedBusiness.id, "products")
          );

          batch.set(newProductRef, {
            name: product?.name?.toLowerCase().trim(),
            originalName: product?.name.trim(),
            features: [],
            categoryId: CATEGORY_NOT_SELECTED,
            subcategorysIds: [],
            tagsList: [],
            tagsIDsList: [],
            stock: parseFloat(product?.stock),
            price: parseFloat(product?.price),
            salePrice:
              product?.salePrice != "" &&
              product?.salePrice != null &&
              product?.salePrice !== undefined
                ? parseFloat(product?.salePrice)
                : null,
            currencyType: currencyType,
            images: [],
            expirationDate: null,
            productNote: product?.productNote ?? "",
            createAt: new Date(),
            createAtMoment: moment().format("LLL"),
            avaliable: parseFloat(product?.stock) > 0 ? true : false,
            imageId: null,
            timestamp: new Date(),
            barCode: product?.barCode ? product?.barCode : null,
            userId: user?.uid,
            username: username,
            videoUrl: null,
          });
        });

        batch
          .commit()
          .then(() => {
            setUploading(false);
            dispatch(
              setShowGeneralSnackbar({
                showSnackbar: true,
              })
            );
            dispatch(
              setGeneralSnackBarMessage({
                snackBarMessage: "Se subieron tus productos correctamente",
              })
            );
            cleanFields();
          })
          .catch((err) => {
            setUploading(false);
            dispatch(
              setShowGeneralSnackbar({
                showSnackbar: true,
              })
            );
            dispatch(
              setGeneralSnackBarMessage({
                snackBarMessage:
                  "Ocurrió un error al intentar crear los productos",
              })
            );
          });
      } catch (err) {
        setUploading(false);
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: "Ocurrió un error al intentar crear los productos",
          })
        );
      }
    }
  };

  const confirmUploadToFirestore = (products: any[]) => {
    if (selectedBusiness?.userId == user?.uid) {
      uploadToFirestoreOWNER(products);
    } else {
      uploadToFirestoreEMPLOYEE(products);
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) return;

    const file = event.target.files[0];
    const fileName = file.name.toLowerCase();

    if (!fileName.endsWith(".xlsx")) {
      setFormError({
        file: true,
        fileMessage: "Solo se permiten archivos .xlsx",
      });
      return;
    }

    setSelectedFile(file);

    // Si pasa la validación, limpia el error y continúa con la carga
    setFormError({});
  };

  const uploadProducts = async (): Promise<void> => {
    if (!selectedFile || uploading) {
      formError("Por favor, selecciona un archivo Excel.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (!e.target?.result) {
        formError("Error al leer el archivo.");
        return;
      }

      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData: any[][] = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      });

      // Columnas esperadas
      const expectedColumns = [
        "name",
        "stock",
        "price",
        "salePrice",
        "barCode",
        "productNote",
      ];

      // Validar encabezados
      const fileColumns = jsonData[0];
      if (
        !fileColumns ||
        !expectedColumns.every((col) => fileColumns.includes(col)) ||
        fileColumns.length !== expectedColumns.length
      ) {
        setFormError({
          file: true,
          fileMessage: `El archivo Excel debe contener exactamente las columnas: ${expectedColumns.join(
            ", "
          )}.`,
        });
        return;
      }

      // Filtrar filas vacías
      const productRows = jsonData
        .slice(1)
        .filter((row) =>
          row.some((cell) => cell !== undefined && cell !== null && cell !== "")
        );

      // Validar cantidad de productos
      /* const productRows = jsonData.slice(1); */
      /* console.log({ productRows }); */
      if (productRows.length > PRODUCTS_MAX_QUANTITY) {
        setFormError({
          file: true,
          fileMessage: `El archivo Excel puede contener como máximo ${PRODUCTS_MAX_QUANTITY} productos.`,
        });
        return;
      }

      // Convertir datos a objetos JSON
      const products: Record<string, any>[] =
        XLSX.utils.sheet_to_json(worksheet);

      // Expresión regular segura para prevenir inyecciones
      const safeInputRegex =
        /^(?!.*(?:\b(SELECT|INSERT|UPDATE|DELETE|DROP|UNION|SCRIPT|ALTER|EXEC|CREATE|TRUNCATE|MERGE|DECLARE)\b|<[^>]*?>)).*$/i;

      const errors: string[] = [];

      // Validaciones
      products.forEach((product, index) => {
        const row = index + 2; // +2 porque la fila 1 es el encabezado y la fila de datos comienza en la 2.

        // Validación de campos obligatorios
        if (!product.name)
          errors.push(`Fila ${row}: El campo "name" es obligatorio.`);

        // Validar stock
        if (product.stock === undefined || product.stock === "") {
          errors.push(`Fila ${row}: El campo "stock" es obligatorio.`);
        } else if (
          typeof product.stock === "string" &&
          product.stock.includes(",")
        ) {
          errors.push(
            `Fila ${row}: El campo "stock" no debe contener comas. Usa un punto para decimales.`
          );
        } else if (isNaN(Number(product.stock)) || Number(product.stock) < 0) {
          errors.push(
            `Fila ${row}: El campo "stock" debe ser un número válido, mayor o igual a cero.`
          );
        }

        // Validar price
        if (product.price === undefined || product.price === "") {
          errors.push(`Fila ${row}: El campo "price" es obligatorio.`);
        } else if (
          typeof product.price === "string" &&
          product.price.includes(",")
        ) {
          errors.push(
            `Fila ${row}: El campo "price" no debe contener comas. Usa un punto para decimales.`
          );
        } else if (isNaN(Number(product.price)) || Number(product.price) <= 0) {
          errors.push(
            `Fila ${row}: El campo "price" debe ser un número válido y mayor a cero.`
          );
        }

        // Validación del campo opcional salePrice (si tiene contenido)
        if (product.salePrice) {
          if (
            typeof product.salePrice === "string" &&
            product.salePrice.includes(",")
          ) {
            errors.push(
              `Fila ${row}: El campo "salePrice" no debe contener comas. Usa un punto para decimales.`
            );
          } else if (
            isNaN(Number(product.salePrice)) ||
            Number(product.salePrice) <= 0
          ) {
            errors.push(
              `Fila ${row}: El campo "salePrice" debe ser un número válido y mayor a cero.`
            );
          }
        }

        // Validación del campo opcional productNote
        if (product.productNote && product.productNote.length > 200) {
          errors.push(
            `Fila ${row}: El campo "productNote" no puede superar los 200 caracteres.`
          );
        }

        // Validación del campo opcional barCode
        if (product.barCode && product.barCode.length > 30) {
          errors.push(
            `Fila ${row}: El campo "barCode" no puede superar los 30 caracteres.`
          );
        }

        // Validación de entrada segura para todos los campos
        Object.entries(product).forEach(([key, value]) => {
          if (typeof value === "string" && !safeInputRegex.test(value)) {
            errors.push(
              `Fila ${row}: El campo "${key}" contiene datos no permitidos.`
            );
          }
        });
      });

      // Mostrar errores si existen
      if (errors.length > 0) {
        setFormError({
          file: true,
          fileMessage: `Se encontraron ${
            errors.length
          } error(es):\n${errors.join("\n")}`,
        });
        return;
      }

      confirmUploadToFirestore(products);

      setFormError({});
      // Aquí puedes agregar la lógica para guardar los productos
    };

    reader.readAsArrayBuffer(selectedFile);
  };

  const deleteFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setFormError({});
  };

  return (
    <>
      <CustomDrawer
        open={isVisible}
        onClose={closeModal}
        anchor="right"
        variant="temporary"
        ModalProps={{
          keepMounted: true,
        }}
        transitionDuration={500}
      >
        <Container>
          <Column>
            <Header>
              <IconButton onClick={closeModal}>
                <ArrowBack />
              </IconButton>
              <Title>Importar productos desde Excel</Title>
            </Header>
            <Content>
              {!userIsPro ? (
                <>
                  <BlockedComponent
                    list={[
                      "Esta es una función de Ganvent PREMIUM",
                      "En esta sección podrás realizar la subida MASIVA DE TUS PRODUCTOS",
                      "Podrás importar tus productos desde un archivo EXCEL, de esta manera te puedes ahorrar mucho tiempo",
                      "Desbloquea esta y más funcionalidades y lleva tu negocio al máximo nivel 🚀",
                    ]}
                  />
                </>
              ) : (
                <>
                  <Text>
                    En esta sección podrás importar productos desde un{" "}
                    <Bold>archivo Excel (.xlsx)</Bold>
                  </Text>
                  <Text style={{ marginTop: "10px" }}>
                    Podrás subir{" "}
                    <Bold>hasta {PRODUCTS_MAX_QUANTITY} productos</Bold> por
                    cada archivo excel
                  </Text>
                  <Text style={{ marginTop: "10px" }}>
                    Si no deseas colocar nada en algún campo que sea 'Opcional',
                    entonces simplemente no coloques nada en dicha celda
                  </Text>
                  <div
                    style={{
                      borderTop: "1px solid #c1c1c1",
                      borderBottom: "1px solid #c1c1c1",
                      padding: "10px 0px",
                      marginTop: "12px",
                      marginBottom: "12px",
                    }}
                  >
                    <input
                      type="file"
                      accept=".xlsx"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                        padding: "12px 0px",
                      }}
                    >
                      {selectedFile == null && (
                        <StyledButtonSelectFile
                          startIcon={<MdOutlineFileUpload />}
                          onClick={() => fileInputRef?.current?.click()}
                        >
                          <StyledButtonSelectFileText>
                            Seleccionar archivo excel
                          </StyledButtonSelectFileText>
                        </StyledButtonSelectFile>
                      )}
                      {selectedFile !== null && (
                        <>
                          <div style={{ flex: 1 }}>
                            <Label>Nombre del archivo seleccionado:</Label>
                            <Filename>{selectedFile?.name}</Filename>
                          </div>
                        </>
                      )}
                    </div>
                    {selectedFile !== null && (
                      <div>
                        <StyledButtonSelectFile
                          startIcon={<MdOutlineFileUpload />}
                          onClick={uploadProducts}
                        >
                          {uploading ? (
                            <CircularProgress
                              style={{ color: "#00a680", margin: "0px 12px" }}
                              size={30}
                            />
                          ) : (
                            <StyledButtonSelectFileText
                              style={{ fontSize: "13px" }}
                            >
                              Subir productos del archivo seleccionado
                            </StyledButtonSelectFileText>
                          )}
                        </StyledButtonSelectFile>
                        <IconButton
                          style={{ marginLeft: "10px" }}
                          onClick={deleteFile}
                          disabled={uploading}
                        >
                          <DeleteIcon style={{ color: "red" }} />
                        </IconButton>
                      </div>
                    )}
                    {formError.file && formError.fileMessage && (
                      <ErrorMessage>{formError.fileMessage}</ErrorMessage>
                    )}
                  </div>
                  <Text style={{ marginTop: "10px", marginBottom: "8px" }}>
                    El archivo debe tener este formato:
                  </Text>
                  <ImageTableContainer>
                    <ImageTable src={require("../../images/importExcel.png")} />
                  </ImageTableContainer>
                  <Text
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Leyenda:
                  </Text>
                  <List>
                    {legendList.map((option, index) => (
                      <LegendItem key={index}>
                        <Circle />
                        <div style={{ flex: 1 }}>
                          <LegendName>
                            {option.name}{" "}
                            {option.mandatory ? "(Obligatorio)" : "(Opcional)"}:
                          </LegendName>
                          <LegendDescription>
                            {option.description}
                          </LegendDescription>
                        </div>
                      </LegendItem>
                    ))}
                  </List>
                  <Text
                    style={{
                      marginTop: "18px",
                      marginBottom: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    Nota:
                  </Text>
                  {/* <Text>
              - Si no deseas colocar nada en algún campo que sea 'Opcional',
              entonces simplemente no coloques nada en dicha celda:
            </Text> */}
                  <Text style={{ marginTop: "12px", marginBottom: "15px" }}>
                    - Las columnas de tu archivo Excel deben tener exactamente
                    los mismos nombres que el formato de ejemplo, es decir,
                    name, stock, price, salePrice, barCode y productNote
                  </Text>
                </>
              )}
            </Content>
          </Column>
          <Column2>
            <ImageContainer>
              <StyledImage src={require("../../images/importProduct.png")} />
            </ImageContainer>
          </Column2>
        </Container>
      </CustomDrawer>
      <CheckUsernameModal
        isVisible={showUsernameModal}
        setIsVisible={setShowUsernameModal}
      />
    </>
  );
}

const CustomDrawer = styled(Drawer)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

const Column = styled.div`
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Column2 = styled.div`
  background-color: #00a680;
  height: 100vh;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledImage = styled.img`
  object-fit: contain;
  height: 60%;
  width: 60%;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  padding: 20px 10px;
  background-color: white;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
`;

const Mandatory = styled.p`
  margin-top: 18px;
  margin-left: 30px;
  font-weight: bold;
  font-size: 15px;
`;

const Title = styled.p`
  flex: 1;
  font-size: 1.3rem;
  font-weight: 600;
`;

const Content = styled.div`
  padding: 20px;
`;

const Text = styled.p`
  font-size: 13px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Circle = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #00a680;
`;

const LegendName = styled.p`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 1px;
`;

const LegendDescription = styled.p`
  font-size: 13px;
`;

const ImageTableContainer = styled.div`
  width: 100%;
  max-height: 600px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageTable = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledButtonSelectFile = styled(Button)`
  &&& {
    text-transform: none;
    border: 1px solid #00a680;
    font-family: "Montserrat";
    margin-top: 15px;
    margin-bottom: 15px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const StyledButtonSelectFileText = styled.p``;

const ErrorMessage = styled.p`
  font-size: 13px;
  text-align: center;
  padding: 0px 20px;
  color: red;
  margin-top: 8px;
  margin-bottom: 10px;
`;

const Label = styled.p`
  font-size: 13px;
  margin-left: 10px;
  margin-bottom: 3px;
`;

const Filename = styled.p`
  margin-left: 10px;
  font-weight: 600;
`;
