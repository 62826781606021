import React, { useState } from "react";
import { FaCrown } from "react-icons/fa";
import styled from "styled-components";
import { MAIN_COLOR } from "../../../utils/colors";
import { LiaObjectGroup } from "react-icons/lia";
import { IconButton } from "@mui/material";
import { IoMdInformationCircleOutline } from "react-icons/io";
import RelatedProductsInformationModal from "../../productPage/RelatedProductsInformationModal";

export default function RelatedProducts() {
  const [showInformation, setShowInformation] = useState(false);

  return (
    <>
      <Header>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flex: 1,
            gap: "4px",
          }}
        >
          <LiaObjectGroup style={{ fontSize: "22px" }} />
          <Label>Productos relacionados</Label>
        </div>
        <IconButton onClick={() => setShowInformation(true)}>
          <IoMdInformationCircleOutline />
        </IconButton>
        <div
          style={{
            backgroundColor: MAIN_COLOR,
            height: "40px",
            width: "40px",
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FaCrown style={{ color: "black", fontSize: "18px" }} />
        </div>
      </Header>
      <Text>
        La lista de <Bold>PRODUCTOS RELACIONADOS</Bold> de este producto es
        parte de Ganvent PREMIUM (Incrementa hasta un 82% tus ventas)
      </Text>
      <RelatedProductsInformationModal
        isVisible={showInformation}
        setIsVisible={setShowInformation}
      />
    </>
  );
}

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 4px;
`;

const Label = styled.p`
  font-size: 13px;
  color: #000000;
  flex: 1;
`;

const Text = styled.p`
  text-align: center;
  font-size: 15px;
  padding: 0p 50px;
  margin-top: 20px;
`;

const Bold = styled.span`
  font-weight: bold;
  color: #00a680;
`;
