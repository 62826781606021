import styled from "styled-components";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../context/store";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { setIsImportProductsModalVisible } from "../../context/reducers/layout";

interface Props {
  list: any[];
  width?: string;
}

export default function BlockedComponent({ list, width = "500px" }: Props) {
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate({
      pathname: "/paywall",
    });
    dispatch(
      setIsImportProductsModalVisible({
        isImportProductsModalVisible: false,
      })
    );
  };

  return (
    <Container>
      <Content style={{ width }}>
        <IconContainer>
          <LockIcon style={{ fontSize: "34px" }} />
        </IconContainer>
        {list.map((item, index) => (
          <Item key={index}>
            <Circle />
            <ItemText>{item}</ItemText>
          </Item>
        ))}
        <Footer>
          <StyledButton style={{ background: "#00a680" }} onClick={handleClick}>
            <StyledButtonText>Ver más</StyledButtonText>
          </StyledButton>
        </Footer>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
`;

const Content = styled.div``;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;

const Footer = styled.footer`
  padding: 12px 10px;
  background-color: white;
  padding-top: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  background-color: #00a680;
  padding: 10px 30px;
  width: 100%;
  border-radius: 12px;
`;

const StyledButtonText = styled.p`
  text-transform: none;
  &&& {
    font-family: "Montserrat";
    color: white;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 12px;
`;

const Circle = styled.div`
  height: 7px;
  width: 7px;
  background-color: #00a680;
  border-radius: 100%;
`;

const ItemText = styled.p`
  font-size: 15px;
  flex: 1;
`;
