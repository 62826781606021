import React, { useEffect, useState } from "react";
import CameraAlt from "@mui/icons-material/CameraAlt";
import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Cropper from "react-easy-crop";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../context/store";
import { FaCrown } from "react-icons/fa";
import { MAIN_COLOR } from "../../../utils/colors";
import { BsFillPencilFill } from "react-icons/bs";
import PremiumModal from "../../global/PremiumModal";

interface Props {
  selectedImage: string;
  setSelectedImage: React.Dispatch<React.SetStateAction<string>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  uploading: boolean;
  croppedAreaPixels: any;
  setCroppedAreaPixels: React.Dispatch<React.SetStateAction<any>>;
}

export default function ProductPhoto({
  selectedImage,
  setSelectedImage,
  setShowModal,
  uploading,
  croppedAreaPixels,
  setCroppedAreaPixels,
}: Props) {
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);
  const [blobUrl, setBlobUrl] = useState("");
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const imagesArray = new Array(5).fill("");

  const callApiToGetCroppedImage = async () => {
    try {
      const newUrl = await getCroppedImgUrl();
      setBlobUrl(newUrl as string);
    } catch (err) {}
  };

  useEffect(() => {
    if (selectedImage) {
      callApiToGetCroppedImage();
    } else {
      setBlobUrl("");
    }
  }, [selectedImage]);

  const deleteImage = () => {
    setSelectedImage("");
  };

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
    });

  const getCroppedImage = async (): Promise<Blob | null> => {
    if (!selectedImage || !croppedAreaPixels) return null;

    const canvas = document.createElement("canvas");
    const image = await createImage(selectedImage);
    const { width, height } = croppedAreaPixels;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        width,
        height,
        0,
        0,
        width,
        height
      );
    }
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) resolve(blob);
      }, "image/jpeg");
    });
  };

  const getCroppedImgUrl = async (): Promise<string | null> => {
    if (!selectedImage || !croppedAreaPixels) return null;

    const canvas = document.createElement("canvas");
    const image = await createImage(selectedImage);
    const { width, height } = croppedAreaPixels;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        width,
        height,
        0,
        0,
        width,
        height
      );
    }

    // Cambiar la implementación de toDataURL para devolver el resultado directamente
    return canvas.toDataURL("image/jpeg");
  };

  return (
    <>
      <Container
        onClick={() => {
          /* if (uploading) return;
          setShowModal(true); */
        }}
      >
        <ImageContainer
          onClick={() => {
            if (uploading) return;
            setShowModal(true);
          }}
          style={{
            backgroundColor: !selectedImage ? "#c1c1c1" : "transparent",
            borderWidth: !selectedImage ? "1px" : "0px",
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", right: "-10px", top: "0px" }}>
            <div
              style={{
                backgroundColor: "#00a680",
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "45px",
                width: "45px",
              }}
            >
              <BsFillPencilFill style={{ color: "white" }} />
            </div>
          </div>
          {selectedImage && blobUrl !== "" ? (
            <StyledImage src={blobUrl} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CameraAlt style={{ fontSize: "30px" }} />
              {!userIsPro && <p style={{ fontSize: "12px" }}>Imagen 1</p>}
            </div>
          )}
        </ImageContainer>
      </Container>
      {!userIsPro && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "10px",
            marginBottom: "25px",
          }}
        >
          {imagesArray.map((image, index) => (
            <ImageItemContainer
              key={index}
              onClick={() => setShowPremiumModal(true)}
            >
              <div
                style={{
                  backgroundColor: MAIN_COLOR,
                  height: "30px",
                  width: "30px",
                  borderRadius: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaCrown style={{ color: "black", fontSize: "16px" }} />
              </div>
              <p style={{ fontSize: "11px" }}>Imagen {index + 2}</p>
            </ImageItemContainer>
          ))}
        </div>
      )}
      {selectedImage && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "20px",
            justifyContent: "flex-end",
          }}
        >
          <StyledIconButton
            onClick={deleteImage}
            startIcon={<Close style={{ color: "red", fontSize: "10px" }} />}
          >
            <ButtonText>Eliminar imagen seleccionada</ButtonText>
          </StyledIconButton>
        </div>
      )}
      <PremiumModal
        isVisible={showPremiumModal}
        setIsVisible={setShowPremiumModal}
        list={[
          `En la versión gratuita, solamente puedes agregar 1 imagen por producto`,
          "Adquiere GANVENT PREMIUM y agrega hasta 6 imágenes por producto para que puedes mostrar todos los detalles de tus productos a tus clientes y así incrementar tus ventas",
        ]}
      />
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 150px;
  width: 150px;
  background-color: #c1c1c1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  border-radius: 100%;
  border: 1px dashed black;
  cursor: pointer;
  transition: all 0.3s ease;
  :hover {
    scale: 1.02;
  }
`;

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const StyledIconButton = styled(Button)`
  &&& {
    border: 1px solid red;
  }
`;

const ButtonText = styled.div`
  &&& {
    text-transform: none;
    font-family: "Montserrat";
    font-size: 11px;
    color: red;
  }
`;

const ImageItemContainer = styled.div`
  border: 1px black dashed;
  height: 90px;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  transition: all ease 0.3s;
  :hover {
    background-color: rgba(155, 155, 155, 0.4);
  }
`;
