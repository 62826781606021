import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import styled from "styled-components";
import { MAIN_COLOR } from "../../../utils/colors";
import { FaCrown } from "react-icons/fa";
import { HiFire } from "react-icons/hi";
import AdvancedTagsInformationModal from "./AdvancedTagsInformationModal";

export default function AdvancedTags() {
  const [showInformation, setShowInformation] = useState(false);

  const tags = [
    {
      id: "IN_OFFER",
      label: "En oferta",
    },
    {
      id: "BEST_SELLER",
      label: "Más vendido",
    },
    {
      id: "RECOMMENDED",
      label: "Recomendado",
    },
    {
      id: "LAST_UNITS",
      label: "Últimas unidades",
    },
    {
      id: "LIMI_EDITION",
      label: "Edición limitada",
    },
    {
      id: "NOVELTY",
      label: "Novedad",
    },
    {
      id: "NEW",
      label: "Nuevo",
    },
    {
      id: "NEWCOMER",
      label: "Recién llegado",
    },
    {
      id: "SPECIAL_DISCOUNT",
      label: "Descuento especial",
    },
    {
      id: "TOP_SALES",
      label: "Top Ventas",
    },
    {
      id: "ONLY_ONLINE",
      label: "Solo online",
    },
    {
      id: "OF_SEASON",
      label: "De temporada",
    },
    {
      id: "BEST_RATED",
      label: "Mejor valorado",
    },
    {
      id: "PRE_ORDER",
      label: "Pre-Orden",
    },
    {
      id: "PROMOTION",
      label: "Promoción",
    },
    {
      id: "LIQUIDATION",
      label: "Liquidación",
    },
    {
      id: "CLOSES_DOORS",
      label: "Cierra puertas",
    },
    {
      id: "POPULAR",
      label: "Popular",
    },
    {
      id: "ONLY_FOR_TODAY",
      label: "Solo Hoy",
    },
    {
      id: "FLASH_OFFER",
      label: "Oferta relámpago",
    },
    {
      id: "NEW_COLLECTION",
      label: "Nueva colección",
    },
    {
      id: "FOR_LIMITED_TIME_ONLY",
      label: "Solo por tiempo limitado",
    },
    {
      id: "2X1",
      label: "Compra 1 lleva 2",
    },
    {
      id: "FREE_DELIVERY",
      label: "Envío gratis",
    },
    {
      id: "PRE_SALE",
      label: "Pre-Venta",
    },
    {
      id: "IDEAL_FOR_GIFT",
      label: "Ideal para regalo",
    },
    {
      id: "LIMITED_STOCK",
      label: "Stock limitado",
    },
    {
      id: "SPECIAL_EDITION",
      label: "Edición especial",
    },
  ];

  return (
    <>
      <>
        <Header>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flex: 1,
              gap: "4px",
            }}
          >
            <HiFire style={{ fontSize: "22px" }} />
            <Label>Etiquetas avanzadas</Label>
          </div>
          <IconButton onClick={() => setShowInformation(true)}>
            <IoMdInformationCircleOutline />
          </IconButton>
          <div
            style={{
              backgroundColor: MAIN_COLOR,
              height: "40px",
              width: "40px",
              borderRadius: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FaCrown style={{ color: "black", fontSize: "18px" }} />
          </div>
        </Header>
        <Text>
          La lista de <Bold>ETIQUETAS AVANZADAS</Bold> son parte de Ganvent
          PREMIUM (De las mejores funcionalidades que tenemos)
        </Text>
        <List>
          {tags.map((tag, index) => (
            <TagItem key={index} tag={tag} />
          ))}
        </List>
        <AdvancedTagsInformationModal
          isVisible={showInformation}
          setIsVisible={setShowInformation}
        />
      </>
    </>
  );
}

interface TagItemI {
  tag: any;
}

const TagItem = ({ tag }: TagItemI) => {
  return (
    <Tag
      style={{
        backgroundColor: "white",
      }}
    >
      <TagName
        style={{
          color: "black",
        }}
      >
        {tag?.label}
      </TagName>
    </Tag>
  );
};

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 4px;
`;

const Label = styled.p`
  font-size: 13px;
  color: #000000;
  flex: 1;
`;

const Text = styled.p`
  text-align: center;
  font-size: 15px;
  padding: 0p 50px;
  margin-top: 20px;
`;

const Bold = styled.span`
  font-weight: bold;
  color: #00a680;
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 80px;
  margin-top: 30px;
`;

const Tag = styled.div`
  border: 1px solid #00a680;
  border-radius: 10px;
  padding: 8px 12px;
  cursor: not-allowed;
  transition: all 0.3s ease;
  :hover {
    background-color: rgba(155, 155, 155, 0.3);
  }
`;

const TagName = styled.p`
  font-size: 14px;
`;
