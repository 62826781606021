import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../context/store";
import { IoCloseCircle } from "react-icons/io5";
import { BsFillTagFill } from "react-icons/bs";
import { BsFillTagsFill } from "react-icons/bs";
import {
  collection,
  db,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from "../../../firebase";
import {
  setCanLoadMore,
  setIsLoadingMoreData,
  setLastTag,
  setTags,
} from "../../../context/reducers/tags";
import styled from "styled-components";
import {
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { FiChevronRight } from "react-icons/fi";
import { TAGS_MAX_QUANTITY_PER_PRODUCT } from "../../../utils/globalConstants";
import {
  setGeneralSnackBarMessage,
  setShowGeneralSnackbar,
} from "../../../context/reducers/layout";
import LockIcon from "@mui/icons-material/Lock";
import { MAIN_COLOR } from "../../../utils/colors";
import { FaCrown } from "react-icons/fa";

interface Props {
  selectedTagsArray: any[];
  setSelectedTagsArray: React.Dispatch<React.SetStateAction<any[]>>;
  selectedTagsIDsArray: any[];
  setSelectedTagsIDsArray: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function TagsList({
  selectedTagsArray,
  selectedTagsIDsArray,
  setSelectedTagsArray,
  setSelectedTagsIDsArray,
}: Props) {
  const selectedBusiness = useSelector(
    (state: RootState) => state.layout.selectedBusiness
  );
  const userIsPro = useSelector((state: RootState) => state.layout.userIsPro);
  const tags = useSelector((state: RootState) => state.tags.tags);
  const lastTag = useSelector((state: RootState) => state.tags.lastTag);
  const canLoadMore = useSelector((state: RootState) => state.tags.canLoadMore);
  const isLoadingMoreData = useSelector(
    (state: RootState) => state.tags.isLoadingMoreData
  );
  const [showModal, setShowModal] = useState(false);
  const isMounted = useRef(false);
  const tagsLimit = 8;
  const dispatch = useDispatch();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (selectedBusiness?.id) {
      const tagsRef = collection(db, "business", selectedBusiness?.id, "tags");
      const q = query(tagsRef, orderBy("name", "asc"), limit(tagsLimit));

      const suscriber = onSnapshot(
        q,
        (snapshot) => {
          if (!snapshot.empty) {
            let tempArray: any[] = [];
            if (isMounted.current) {
              dispatch(
                setLastTag({
                  lastTag: snapshot.docs[snapshot.docs.length - 1],
                })
              );
            }
            snapshot.forEach((doc) => {
              let object = doc.data();
              object.id = doc.id;
              tempArray.push(object);
            });
            if (isMounted.current) {
              dispatch(
                setTags({
                  tags: tempArray,
                })
              );
            }
          } else {
            if (isMounted.current) {
              dispatch(
                setTags({
                  tags: [],
                })
              );
            }
          }
        },
        (err) => {
          console.log("Err");
        }
      );
      return () => {
        suscriber();
      };
    }
  }, []);

  const handleLoadMore = () => {
    if (canLoadMore && !isLoadingMoreData) {
      dispatch(
        setCanLoadMore({
          canLoadMore: true,
        })
      );

      dispatch(
        setIsLoadingMoreData({
          isLoadingMoreData: true,
        })
      );

      const tagsRef = collection(db, "business", selectedBusiness?.id, "tags");
      const q = query(
        tagsRef,
        orderBy("name", "asc"),
        startAfter(lastTag),
        limit(tagsLimit)
      );

      onSnapshot(
        q,
        (snapshot) => {
          if (!snapshot.empty) {
            let tempArray: any[] = [];
            if (isMounted.current) {
              dispatch(
                setLastTag({
                  lastTag: snapshot.docs[snapshot.docs.length - 1],
                })
              );
            }
            snapshot.forEach((doc) => {
              let object = doc?.data();
              object.id = doc?.id;
              tempArray.push(object);
            });
            if (isMounted.current) {
              dispatch(
                setTags({
                  tags: [...(tags as any[]), ...tempArray],
                })
              );
            }
          }
          if (snapshot.empty) {
            dispatch(
              setCanLoadMore({
                canLoadMore: false,
              })
            );
          } else {
            dispatch(
              setCanLoadMore({
                canLoadMore: true,
              })
            );
          }
          dispatch(
            setIsLoadingMoreData({
              isLoadingMoreData: false,
            })
          );
        },
        (err) => {
          console.log("Error: ", err);
        }
      );
    } else {
      if (isMounted.current) {
        dispatch(
          setCanLoadMore({
            canLoadMore: false,
          })
        );
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const checkIfTagIsInTagsIDsArray = (tag: any): boolean => {
    return selectedTagsIDsArray?.includes(tag?.id);
  };

  const checkIfTagIsInTagsArray = (tag: any): boolean => {
    let band = false;

    selectedTagsArray?.forEach((item, index) => {
      if (item?.id == tag?.id) {
        band = true;
      }
    });

    return band;
  };

  const selectTag = (item: any) => {
    if (selectedTagsArray?.length < TAGS_MAX_QUANTITY_PER_PRODUCT) {
      if (!checkIfTagIsInTagsIDsArray(item) && !checkIfTagIsInTagsArray(item)) {
        let newTag = {
          id: item?.id,
          name: item?.name,
        };
        setSelectedTagsArray([...selectedTagsArray, newTag]);
        setSelectedTagsIDsArray([...selectedTagsIDsArray, item?.id]);
        setShowModal(false);
      } else {
        dispatch(
          setShowGeneralSnackbar({
            showSnackbar: true,
          })
        );
        dispatch(
          setGeneralSnackBarMessage({
            snackBarMessage: `Esta etiqueta ya está agregada en la lista de etiquetas del producto`,
          })
        );
      }
    } else {
      dispatch(
        setShowGeneralSnackbar({
          showSnackbar: true,
        })
      );
      dispatch(
        setGeneralSnackBarMessage({
          snackBarMessage: `Máximo ${TAGS_MAX_QUANTITY_PER_PRODUCT} etiquetas por producto`,
        })
      );
    }
  };

  const handleDelete = (item: any) => {
    //Primero eliminamos el tag del selectedTagsArray
    setSelectedTagsArray(
      selectedTagsArray.filter((tag) => tag.id !== item?.id)
    );

    //Luego eliminamos el id del selectedTagsIDsArray
    setSelectedTagsIDsArray(
      selectedTagsIDsArray.filter((tagId) => tagId !== item?.id)
    );
  };

  return (
    <>
      <Header>
        <BsFillTagsFill />
        <Label>Etiquetas</Label>
        {!userIsPro ? (
          <div
            style={{
              backgroundColor: MAIN_COLOR,
              height: "40px",
              width: "40px",
              borderRadius: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FaCrown style={{ color: "black", fontSize: "18px" }} />
          </div>
        ) : (
          <IconButton onClick={() => setShowModal(true)}>
            <Add style={{ color: "#00a680" }} />
          </IconButton>
        )}
      </Header>
      {!userIsPro ? (
        <Premium>
          Las <Highlight>ETIQUETAS</Highlight> son parte de Ganvent PREMIUM
        </Premium>
      ) : selectedTagsArray?.length > 0 ? (
        <>
          <List>
            {selectedTagsArray.map((tag, index) => (
              <SelectedTagItem key={`${tag?.id}_${index}`}>
                <BsFillTagFill />
                <TagName>{capitalizeFirstLetter(tag?.name ?? "")}</TagName>
                <IconButton onClick={() => handleDelete(tag)}>
                  <IoCloseCircle />
                </IconButton>
              </SelectedTagItem>
            ))}
          </List>
        </>
      ) : (
        <>
          <NotFound>
            Todavía no has seleccionado ninguna etiqueta (Opcional)
          </NotFound>
        </>
      )}
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Content>
          <StyledPaper>
            <Title>Selecciona las etiquetas de este producto</Title>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </StyledPaper>
          {tags == null ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginTop: "80px",
              }}
            >
              <CircularProgress
                style={{
                  color: "#00a680",
                  marginTop: "10px",
                  marginBottom: "8px",
                }}
                size={30}
              />
            </div>
          ) : tags?.length == 0 ? (
            <>
              <div style={{ marginTop: "60px" }} />
              <NotFound>
                No has agregado ninguna etiqueta al inventario de tu negocio.
              </NotFound>
              <NotFound>
                Para agregar una nueva etiqueta, dirígete al listado de
                etiquetas de tu inventario
              </NotFound>
            </>
          ) : (
            <Scroll>
              {tags?.map((item, index) => (
                <Item key={index} onClick={() => selectTag(item)}>
                  <ItemText>{capitalizeFirstLetter(item.name ?? "")}</ItemText>
                  <FiChevronRight />
                </Item>
              ))}
              {canLoadMore &&
                (isLoadingMoreData ? (
                  <ButtonLoadMoreContainer>
                    <ButtonLoadMore
                      disabled
                      style={{ paddingLeft: "30px", paddingRight: "30px" }}
                    >
                      <CircularProgress
                        style={{ color: "#00a680" }}
                        size={20}
                      />
                    </ButtonLoadMore>
                  </ButtonLoadMoreContainer>
                ) : (
                  <ButtonLoadMoreContainer>
                    <ButtonLoadMore
                      onClick={handleLoadMore}
                      startIcon={<Add style={{ color: "#00a680" }} />}
                    >
                      <ButtonLoadMoreText>
                        Cargar más etiquetas
                      </ButtonLoadMoreText>
                    </ButtonLoadMore>
                  </ButtonLoadMoreContainer>
                ))}
            </Scroll>
          )}
        </Content>
      </Modal>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #c1c1c1;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 12px 10px;
  cursor: pointer;

  transition: all 0.1s ease;

  :hover {
    background-color: rgba(155, 155, 155, 0.1);
  }
`;

const Text = styled.p`
  flex: 1;
  font-size: 15px;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 4px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

const Label = styled.p`
  font-size: 13px;
  color: #000000;
  flex: 1;
`;

const Content = styled.div`
  background-color: white;
  width: 80vw;
  max-width: 600px;
  height: 90vh;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`;

const Scroll = styled.div`
  overflow-y: scroll;
  flex: 1;
`;

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 14px;
  padding-left: 14px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`;

const Title = styled.p`
  padding: 25px 0px;
  flex: 1;
  font-weight: 600;
`;

const Item = styled.div`
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 20px;
  border-bottom: 1px solid #c1c1c1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  :hover {
    background-color: rgba(155, 155, 155, 0.3);
  }
`;

const ItemText = styled.p`
  text-align: center;
  flex: 1;
`;

const NotFound = styled.p`
  text-align: center;
  margin: 0px 40px;
  margin-top: 8px;
  font-size: 13px;
  font-weight: 500;
`;

const ButtonLoadMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonLoadMore = styled(Button)`
  &&& {
    border: 1px solid #00a680;
    padding: 15px 20px;
    border-radius: 10px;
    margin-top: 12px;
  }
`;

const ButtonLoadMoreText = styled.p`
  &&& {
    text-transform: none;
    font-family: "Montserrat";
  }
`;

const StyledButton = styled(Button)`
  position: fixed;
  bottom: 100px;
  right: 30px;
  &&& {
    background-color: #00a680;
    padding: 12px 15px;
    border-radius: 12px;
    box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 2px 4px 5px 0px rgba(0, 0, 0, 0.16);
  }
`;

const StyledButtonText = styled.p`
  text-transform: none;
  color: white;
  &&& {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1rem;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
`;

const SelectedTagItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  padding: 0px 8px;
  padding-right: 2px;
`;

const TagName = styled.p`
  font-size: 13px;
`;

const Highlight = styled.span`
  font-weight: 600;
  color: #00a680;
`;

const Premium = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-top: 12px;
`;
